import { useState, useMemo } from "react";
import {
  getColors,
  getTypes,
  getUpgrades,
} from "../../../../../utils/functions";

const StepsFooter = ({
  totalAmount,
  goToNextStep,
  addToCart,
  sections,
  lastStep,
  setStep,
  tab,
  products,
  activeColor,
  activeType,
  activeUpgrades,
  file,
  step,
  detailType,
  puppilaryDistanceCount,
  prescription,
  confirmationAccept,
}) => {
  const [showSections, setShowSections] = useState(false);
  const lensColors = useMemo(() => {
    if (tab !== "sunglasses") return null;
    return getColors(products);
  }, [products]);

  const prescriptionType = useMemo(() => getTypes(products), [products]);
  const upgradeTypes = useMemo(() => {
    return getUpgrades(
      products.filter(
        (el) => el.title.includes(activeType)
      ),
      tab === 'eyeglasses' ? 'Clear':activeColor,
      activeType
    );
  }, [activeType, tab, products]);

  const getPriceOfSelectedColor = () => {
    if (tab !== "sunglasses") return "Free";
    const selectedColor = lensColors.find(
      (color) => color.color === activeColor
    );
    return selectedColor.price;
  };

  const getPriceOfSelectedType = () => {
    //if (tab !== "eyeglasses" || tab !== 'sunglasses') return "Free";
    const selectedType = prescriptionType.find((product) => product.type === activeType);
    if(selectedType === undefined || selectedType.price === 0) return "Free"
    else return `+$${selectedType?.price}`;
  };

  const getPriceOfUpgradedType = () => {
    const selectedUpgrade = upgradeTypes.find((upgrade) =>
      upgrade.upgrade.includes(activeUpgrades)
    );
    return selectedUpgrade?.price;
  };

  const goToExactStep = (e) => {
    const clickedSection = sections.find((el) => el.title === e.title);
    if (clickedSection && clickedSection.title === e.title) {
      setShowSections(false);
      const index = sections.indexOf(clickedSection);
      if (e.title !== "Sunglasses Frame" && e.title !== "Eyeglasses Frame") {
        setStep(index);
      }
    }
  };

  const nextBtnActive = () => {
    const flag = tab === 'sunglasses' ? 4 : 3;
    if (step === flag) {
      if (detailType === 1) {
        if ( confirmationAccept && prescription.os.sphere && prescription.os.cylinder && prescription.os.axis && prescription.od.sphere && prescription.od.cylinder && prescription.od.axis && ((puppilaryDistanceCount === 1 && prescription.pd.total) || (puppilaryDistanceCount === 2 && prescription.pd.os && prescription.pd.od) || (puppilaryDistanceCount === 0 && prescription.pd.total))) {
          return true;
        }
        else {
          return false
        }
      }
      else if (detailType === 2) {
        if (file && ((puppilaryDistanceCount === 1 && prescription.pd.total) || (puppilaryDistanceCount === 2 && prescription.pd.os && prescription.pd.od))) {
          return true;
        }
        else {
          return false
        }
      }
    }
    return true;
  }

  return (
    <div className="flex flex-col mt-auto border-t-2 pt-[20px]">
      {!lastStep && (
        <div className="flex flex-col flex-between">
          {showSections && (
            <div className="flex flex-col gap-[5px]">
              {sections.map((el) => (
                <div key={el.title} className="flex flex-row relative">
                  {el.value?.length ? (
                    <div
                      className="flex flex-row gap-[5px]"
                      onClick={() => {
                        goToExactStep(el);
                      }}
                    >
                      <span className="text-[#212121] text-[14px] underline">
                        {el.title}:
                      </span>
                      {el.title === "Lens Upgrade" ? (
                        <div>
                          <span className="text-[#212121] text-[14px]">
                            {el.value.join(" + ")}
                          </span>
                          { getPriceOfUpgradedType() !== undefined &&
                            <span className="text-[#212121] text-[14px] absolute right-0">
                              +${getPriceOfUpgradedType()}
                            </span>
                          }
                          
                        </div>
                      ) : (
                        <div>
                          <span className="text-[#212121] text-[14px]">
                            {el.value}
                          </span>
                          {el.title === "Lens Type" && activeType && (
                            <span className="text-[#212121] text-[14px] absolute right-0">
                              {getPriceOfSelectedType()}
                            </span>
                          )}
                          {el.title === "Lens Color" && activeColor && (
                            <span className="text-[#212121] text-[14px] absolute right-0">
                              +${getPriceOfSelectedColor()}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div
        className={`flex flex-row items-center py-[10px] ${lastStep ? "justify-end" : "justify-between"
          }`}
      >
        {!lastStep && (
          <button
            onClick={() => setShowSections(!showSections)}
            className="w-fit px-[21px] py-[7px] flex justify-center items-center border border-[#E6E6E6] rounded-[18px]"
          >
            Your Selection
            <span className="ml-auto pl-[10px]">
              <img
                src="/svgs/arrow-down.svg"
                alt="arrow-down"
                className={`${showSections ? "transform rotate-180" : ""
                  } transition ease-in-out duration-200`}
              />
            </span>
          </button>
        )}
        <div className={`flex items-center`}>
          <div className="flex gap-[5px]">
            <p className="uppercase">Subtotal</p>
            <p className="font-bold">${Math.trunc(totalAmount)}</p>
          </div>
        </div>
      </div>
      {!nextBtnActive() &&
        <button
          onClick={lastStep ? addToCart : goToNextStep}
          className="mb-[40px] flex justify-center items-center transition rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-slate-100 hover:bg-slate-200 text-black uppercase text-[14px] font-bold"
          disabled>
          {lastStep ? "Add To Cart" : "Next Step"}
        </button>
      }
      {nextBtnActive() &&
        <button
          onClick={lastStep ? addToCart : goToNextStep}
          className="mb-[40px] flex justify-center items-center transition rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-gradient-to-r from-[#0457A1] hover:from-[#8CD9F5] to-[#002668] hover:to-[#0175D4] text-white uppercase text-[14px] font-bold">
          {lastStep ? "Add To Cart" : "Next Step"}
        </button>
      }
    </div>
  );
};

export default StepsFooter;
