import { useRef, useState } from "react";
import FileInput from "../../../../shared/FileInput";
import { useMemo } from "react";
import { ARShades_PD_Interface } from "@spaarkly-srl/arshades_arpdmeter_web";
import { Dialog, DialogPanel } from "@headlessui/react";

const Step4 = ({
  file,
  setFile,
  setPrescription,
  activeType,
  detailType,
  setDetailType,
  confirmationAccept,
  setConfirmationAccept,
  puppilaryDistanceCount,
  setPuppilaryDistanceCount,
}) => {
  const axis = useMemo(() => {
    const start = 0;
    const end = 180;
    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  }, []);

  const sphere = useMemo(() => {
    const start = -8;
    const end = 3;
    const increment = 0.25;
    return Array.from({ length: ((end - start) / increment) + 1 }, (_, i) => start + i * increment);
  }, []);

  const cylinder = useMemo(() => {
    const start = -3;
    const end = 3;
    const increment = 0.25;
    return Array.from({ length: ((end - start) / increment) + 1 }, (_, i) => start + i * increment);
  }, []);

  const add = useMemo(() => {
    const start = 0.75;
    const end = 3;
    const increment = 0.25;
    return Array.from({ length: ((end - start) / increment) + 1 }, (_, i) => start + i * increment);
  }, []);

  const pdSingle = useMemo(() => {
    const start = 27;
    const end = 37;
    const increment = 0.25;
    return Array.from({ length: ((end - start) / increment) + 1 }, (_, i) => start + i * increment);
  }, []);

  const pdDouble = useMemo(() => {
    const start = 54;
    const end = 74;
    const increment = 0.25;
    return Array.from({ length: ((end - start) / increment) + 1 }, (_, i) => start + i * increment);
  }, []);

  const gridTemplateColumns = activeType === "Progressive" ? "repeat(4, 1fr)" : "repeat(3, 1fr)";

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const [eye, prop] = name.split('-');
    setPrescription((prevState) => ({
      ...prevState,
      [eye]: { ...prevState[eye], [prop]: value },
    }));
  };

  const iframeUploadRef = useRef(null);
  function handleButtonClick() {
    
    const iframeDoc = iframeUploadRef.current.contentWindow.document;
    const iframeContent = iframeDoc.documentElement.innerHTML;
    console.log(iframeContent);
    
  }

  let [isOpen, setIsOpen] = useState(false);
  const [myPd, setMyPd] = useState(0);
  const [modalClose, setModalClose] = useState(false);

  const handleResult = (result) => {
    setMyPd(result);
    setModalClose(true);
    const eye = 'pd';
    const prop = 'total';
    setPrescription((prevState) => ({
      ...prevState,
      [eye]: { ...prevState[eye], [prop]: result },
    }));
  }

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex lg:flex-col flex-row justify-between">
        <p className="text-[#232B38] font-serif  font-extrabold text-[24px]">Prescription Details</p>
      </div>
      <div className="flex flex-col gap-[10px]">
        <div onClick={() => setDetailType(0)} className="flex gap-[10px] items-start">
          <div
            className={`p-[3px] h-fit rounded-full border-2 ${detailType === 0 ? 'border-blue-400' : 'border-gray-400'}`}>
            <div 
              className={`w-[14px] h-[14px] rounded-full ${detailType === 0 ? 'bg-blue-400' : ''}`} />
          </div>
          <div className="flex flex-col">
            <p>Send It Later</p>
            {detailType === 0 && <p>Don’t worry, we’ll remind you</p>}
          </div>
        </div>
        <div onClick={() => setDetailType(1)} className="flex gap-[10px] items-start">
          <div
            className={`p-[3px] h-fit rounded-full border-2 ${detailType === 1 ? 'border-blue-400' : 'border-gray-400'}`}>
            <div 
              className={`w-[14px] h-[14px] rounded-full ${detailType === 1 ? 'bg-blue-400' : ''}`} />
          </div>
          <div className="flex flex-col">
            <p>Enter Prescription Manually</p>
            {detailType === 1 && (
              <div
                className="flex flex-col [&_select]:h-[36px] [&_select]:border [&_select]:border-[#E6E6E6] [&_select]:bg-white [&_select]:px-[10px] [&_label]:font-bold [&_label]:text-[#6A6C77] [&_label]:uppercase [&_label]:text-[11px]"
              >
                <div className="flex flex-col lg:gap-[10px]">
                  <div className="flex flex-row lg:flex-col w-full gap-[45px] lg:gap-[20px]">
                    <div className="flex flex-col w-full">
                      <p className="text-[#212121] text-[16px] font-bold">OD - Right Eye</p>
                      <div style={{ display: 'grid', gridTemplateColumns }}>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="od-sphere">Sphere</label>
                          <select name="od-sphere" id="od-sphere" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {sphere.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="od-cylinder">Cylinder</label>
                          <select name="od-cylinder" id="od-cylinder" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {cylinder.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="od-axis">Axis</label>
                          <select name="od-axis" id="od-axis" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {axis.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        { activeType === "Progressive" &&
                        <div className="flex flex-col pr-2">
                          <label htmlFor="od-add">Add</label>
                          <select name="od-add" id="od-add" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {add.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        }
                      </div>
                    </div>
                    <div className="flex flex-col w-full">
                      <p className="text-[#212121] text-[16px] font-bold">OS - Left Eye</p>
                      <div style={{ display: 'grid', gridTemplateColumns }}>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="os-sphere">Sphere</label>
                          <select name="os-sphere" id="os-sphere" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {sphere.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="os-cylinder">Cylinder</label>
                          <select name="os-cylinder" id="os-cylinder" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {cylinder.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        <div className="flex flex-col pr-2">
                          <label htmlFor="os-axis">Axis</label>
                          <select name="os-axis" id="os-axis" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {axis.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        { activeType === "Progressive" &&
                        <div className="flex flex-col pr-2">
                          <label htmlFor="os-add">Add</label>
                          <select name="os-add" id="os-add" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {add.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pt-[20px]">
                    <p className="text-[#212121] text-[16px] font-bold">Pupillary Distance (PD)</p>
                    <p className="text-[16px] text-[#212121] leading-[19px]">PD is the distance between your pupils.
                      Don’t have it? Don’t stress! Most doctors do not
                      provide this and you can measure yours at AccuPD.com.</p>
                    <div className="flex flex-row py-[20px] gap-[10px]">
                      <button
                        className={`text-[#212121] text-[12px] font-bold px-[20px] py-[5px] rounded-[30px] border ${puppilaryDistanceCount === 1 ? 'border-[#212121]' : 'border-[#E6E6E6]'}`}
                        onClick={() => setPuppilaryDistanceCount(1)}
                      >
                        My PD has 1 number
                      </button>
                      <button
                        className={`text-[#212121] text-[12px] font-bold px-[20px] py-[5px] rounded-[30px] border ${puppilaryDistanceCount === 2 ? 'border-[#212121]' : 'border-[#E6E6E6]'}`}
                        onClick={() => setPuppilaryDistanceCount(2)}
                      >
                        My PD has 2 numbers
                      </button>
                      <button
                        className={`text-[#212121] text-[12px] font-bold px-[20px] py-[5px] rounded-[30px] border ${isOpen === true ? "border-[#212121]" : "border-[#E6E6E6]"}`}
                        onClick={() => {setPuppilaryDistanceCount(0); setIsOpen(true);}}
                      >
                        Measure My PD
                      </button>
                      <Dialog
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        className="relative z-50"
                      >
                        <div className="fixed inset-0 flex w-screen items-center justify-center">
                          <DialogPanel className="measure-app-modal relative w-full h-full">
                            <ARShades_PD_Interface
                              licenseToken={"U2FsdGVkX1+Uc6bKzMyW0QL/WLXlgxsWJjd+ooRa4JSmmR1wT0fw69iedpn/OEpE"}
                              onResultAvailable={handleResult}
                            />
                            {modalClose && <div className="absolute left-1/2 top-1/2 translate-x-[290px] -translate-y-[224px] z-[99]">
                              <button onClick={() => {setIsOpen(false); setModalClose(false);}}>
                                <svg aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.414 7l5.293-5.293A.999.999 0 1 0 12.293.293L7 5.586 1.707.293A.999.999 0 1 0 .293 1.707L5.586 7 .293 12.293a.999.999 0 1 0 1.414 1.414L7 8.414l5.293 5.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L8.414 7z"
                                    fill="currentColor" />
                                </svg>
                              </button>
                            </div>}
                          </DialogPanel>
                        </div>
                      </Dialog>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                      {
                        puppilaryDistanceCount === 1 && 
                        <select name="pd-total" id="pd-total" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                          {pdDouble.map((i) => <option key={i} value={i}>{i}</option>)}
                        </select>
                      }
                      {
                        puppilaryDistanceCount === 2 && <>
                          <select name="pd-os" id="pd-os" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {pdSingle.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                          <select name="pd-od" id="pd-od" onChange={handleSelectChange}>
                            <option value="DEFAULT" selected disabled>Select</option>
                            {pdSingle.map((i) => <option key={i} value={i}>{i}</option>)}
                          </select>
                        </>

                      }
                      {
                        puppilaryDistanceCount === 0 && myPd !== 0 &&
                        <div>My PD: {myPd}</div>
                      }
                    </div>
                    <div className="flex items-center gap-[10px] py-[20px]">
                      <div
                        className={`flex justify-center items-center min-w-[26px] h-[26px] border-2 ${confirmationAccept ? 'bg-[#08B1FF] border-[#08B1FF]' : 'border-[#E6E6E6]'} rounded`}
                        onClick={() => setConfirmationAccept(!confirmationAccept)}>
                        {confirmationAccept && <img src="/svgs/checked.svg" alt="checked" />}
                      </div>
                      <p>I confirm the prescription entered is valid.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div onClick={() => setDetailType(2)} className="flex gap-[10px] items-start">
          <div
            className={`p-[3px] h-fit rounded-full border-2 ${detailType === 2 ? 'border-blue-400' : 'border-gray-400'}`}>
            <div 
              className={`w-[14px] h-[14px] rounded-full ${detailType === 2 ? 'bg-blue-400' : ''}`} />
          </div>
          <div className="flex flex-col">
            <p>Upload a Photo</p>
            {detailType === 2 && (
              <div className="flex flex-col pt-[20px] [&_select]:h-[36px] [&_select]:border [&_select]:border-[#E6E6E6] [&_select]:bg-white [&_select]:px-[10px] [&_label]:font-bold [&_label]:text-[#6A6C77] [&_label]:uppercase [&_label]:text-[11px]">
              <p className="text-[#212121] text-[16px] font-bold">Pupillary Distance (PD)</p>
              <p className="text-[16px] text-[#212121] leading-[19px]">PD is the distance between your pupils.
                Don’t have it? Don’t stress! Most doctors do not
                provide this and you can measure yours at AccuPD.com.</p>
              <div className="flex flex-row py-[20px] gap-[10px]">
                <button
                  className={`text-[#212121] text-[12px] font-bold px-[20px] py-[5px] rounded-[30px] border ${puppilaryDistanceCount === 1 ? 'border-[#212121]' : 'border-[#E6E6E6]'}`}
                  onClick={() => setPuppilaryDistanceCount(1)}
                >
                  My PD has 1 number
                </button>
                <button
                  className={`text-[#212121] text-[12px] font-bold px-[20px] py-[5px] rounded-[30px] border ${puppilaryDistanceCount === 2 ? 'border-[#212121]' : 'border-[#E6E6E6]'}`}
                  onClick={() => setPuppilaryDistanceCount(2)}
                >
                  My PD has 2 numbers
                </button>
              </div>
              <div className="flex flex-col gap-[20px]">
                {
                  puppilaryDistanceCount === 1 && 
                  <select name="pd-total" id="pd-total" onChange={handleSelectChange}>
                      <option value="DEFAULT" selected disabled>Select</option>
                    {pdDouble.map((i) => <option key={i} value={i}>{i}</option>)}
                  </select>
                }
                {
                  puppilaryDistanceCount === 2 && <>
                    <select name="pd-os" id="pd-os" onChange={handleSelectChange}>
                      <option value="DEFAULT" selected disabled>Select</option>
                      {pdSingle.map((i) => <option key={i} value={i}>{i}</option>)}
                    </select>
                    <select name="pd-od" id="pd-od" onChange={handleSelectChange}>
                      <option value="DEFAULT" selected disabled>Select</option>
                      {pdSingle.map((i) => <option key={i} value={i}>{i}</option>)}
                    </select>
                  </>

                }
              </div>
              <div className="flex flex-col items-center py-[10px]"></div>
              <p className="text-center">Send us your prescription and we’ll take care of it.</p>
                <FileInput label="Take a Photo" onChange={setFile} value={file} />
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;