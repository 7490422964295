import ProgressBar from "../../../shared/ProgressBar";
import { useEffect, useMemo, useState } from "react";
import StepsFooter from "./StepsFooter";
import SunglassesSteps from "./SunglassesSteps";
import InfoModal from "../../InfoModal";
import EyeglassesSteps from "./EyeglassesSteps";

import { useParams } from "react-router-dom";

const Steps = ({
  tab,
  product,
  products,
  closeModal,
  activeType,
  activeColor,
  setActiveType,
  setActiveColor,
  activeUpgrades,
  setActiveUpgrades,
}) => {
  const { shopId } = useParams();

  const [step, setStep] = useState(1);
  const [count, setCount] = useState(0);
  const [infoModal, setInfoModal] = useState(false);
  const [detailType, setDetailType] = useState(0);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [file, setFile] = useState(null);
  const [confirmationAccept, setConfirmationAccept] = useState(false);
  const [puppilaryDistanceCount, setPuppilaryDistanceCount] = useState(1);

  const [prescription, setPrescription] = useState({
    od: {
      sphere: null,
      cylinder: null,
      axis: null,
      add: null,
    },
    os: {
      sphere: null,
      cylinder: null,
      axis: null,
      add: null,
    },
    pd: {
      od: null,
      os: null,
      total: null,
    }
  });

  useEffect(() => {
    setCount(tab === "sunglasses" ? 5 : 4);
  }, [tab]);

  const goToPrevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const goToNextStep = () => {
    if (step < count) setStep(step + 1);
  };

  const onUpgradeChange = (title) => {
    setActiveUpgrades([title]);
  };

  const onAddToCart = () => {
    setInfoModal(true);
  };

  const onClose = () => {
    setInfoModal(false);
    closeModal();
  };

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const submitWithEmail = async (e) => {
    e.preventDefault();
  };
  
  const submitWithName = async (e) => {
    e.preventDefault();
    var data = {};
     if (detailType === 1) {
      data = {
        file_reference: "TY-" + makeid(9),
        left_sphere: prescription.os.sphere,
        left_cylinder: prescription.os.cylinder,
        left_axis: prescription.os.axis,
        left_add: prescription.os.add,
        right_sphere: prescription.od.sphere,
        right_cylinder: prescription.od.cylinder,
        right_axis: prescription.od.axis,
        right_add: prescription.od.add,
      };
      if (puppilaryDistanceCount === 1 || puppilaryDistanceCount === 0) {
        data.left_pd = Math.round(((prescription.pd.total / 2) * 4) / 4);
        data.right_pd = Math.round(((prescription.pd.total / 2) * 4) / 4);
      }
      else if (puppilaryDistanceCount === 2) {
        data.left_pd = prescription.pd.os;
        data.right_pd = prescription.pd.od;
      }

      fetch("https://api.blendersapps.com/addRxV2", {
        method: "POST",
        body: JSON.stringify(data),
      }).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("customer_lastName", lastName);
        urlencoded.append("customer_firstName", firstName);
        urlencoded.append("productId", product.id.match(/\d+/)[0]);
        urlencoded.append("productName", product.frameTitle + " " + product.title);
        urlencoded.append("prescriptionFile", data.file_reference);
        urlencoded.append("shopId", shopId);
        urlencoded.append("type", "rx");

        var requestOptions = {
          method: 'POST',
          body: urlencoded  
        };
        //Shopify APP
        fetch("https://blendsinstore.blendersapps.com/api/addorder", requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
        .catch((error) => {
          console.error(error);
      });

    }
    /**************************
     * 
     * 
     * Upload Picture
     * 
     * 
     **************************/

    else if (detailType === 2) {
      const refId = makeid(12);

      const formData = new FormData();
      formData.append('formID', '202167252443045');
      formData.append('q3_referenceName', refId);
      formData.append('q5_uploadYour[]', file, file.name);
      if (puppilaryDistanceCount === 1) {
        formData.append('q6_leftPd', Math.round(((prescription.pd.total / 2) * 4) / 4));
        formData.append('q7_rightPd', Math.round(((prescription.pd.total / 2) * 4) / 4));
      }
      else if (puppilaryDistanceCount === 2) {
        formData.append('q6_leftPd', prescription.pd.os);
        formData.append('q7_rightPd', prescription.pd.od);
      }

      fetch('https://hipaa.jotform.com/submit/202167252443045', {
        method: 'POST',
        body: formData,
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("customer_lastName", lastName);
        urlencoded.append("customer_firstName", firstName);
        urlencoded.append("productId", product.id.match(/\d+/)[0]);
        urlencoded.append("productName", product.frameTitle + " " + product.title);
        urlencoded.append("shopId", shopId);
        urlencoded.append("prescriptionFile", refId);
        urlencoded.append("type", "rx");

        var requestOptions = {
          method: 'POST',
          body: urlencoded  
        };
        //Shopify APP
        fetch("https://blendsinstore.blendersapps.com/api/addorder", requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    }
     /**************************
     * 
     * 
     * Later
     * 
     * 
     **************************/
    else{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("customer_lastName", lastName);
      urlencoded.append("customer_firstName", firstName);
      urlencoded.append("productId", product.id.match(/\d+/)[0]);
      urlencoded.append("productName", product.frameTitle + " " + product.title);
      urlencoded.append("shopId", shopId);
      urlencoded.append("type", "rx");

      var requestOptions = {
        method: 'POST',
        body: urlencoded  
      };
      //Shopify APP
      fetch("https://blendsinstore.blendersapps.com/api/addorder", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }
  }

  

  const sections = useMemo(() => {
    return tab === "sunglasses"
      ? [
        { title: "Sunglasses Frame", value: products.title },
        { title: "Lens Color", value: activeColor },
        { title: "Lens Type", value: activeType },
        { title: "Lens Upgrade", value: activeUpgrades },
      ]
      : [
        { title: "Eyeglasses Frame", value: products.title },
        { title: "Lens Type", value: activeType },
        { title: "Lens Upgrade", value: activeUpgrades },
      ];
  }, [products, activeColor, activeType, activeUpgrades]);

  return (
    <div className="h-full flex w-full flex-col px-[50px] pt-[30px] gap-[30px]">
      <div className="relative flex w-full">
        {step !== 1 && (
          <button onClick={() => goToPrevStep()}>
            <img src="/svgs/arrow.svg" alt="arrow-back" className="absolute" />
          </button>
        )}
        <p className="mx-auto uppercase">
          step {step}/{count}
        </p>
      </div>
      <ProgressBar currentStep={step} stepCount={count} />
      {tab === "sunglasses" ? (
        <SunglassesSteps
          tab={tab}
          step={step}
          file={file}
          setFile={setFile}
          setPrescription={setPrescription}
          upgrade={activeUpgrades}
          sections={sections}
          products={products}
          detailType={detailType}
          activeType={activeType}
          activeColor={activeColor}
          setDetailType={setDetailType}
          setActiveType={setActiveType}
          setActiveColor={setActiveColor}
          onUpgradeChange={onUpgradeChange}
          confirmationAccept={confirmationAccept}
          setConfirmationAccept={setConfirmationAccept}
          puppilaryDistanceCount={puppilaryDistanceCount}
          setPuppilaryDistanceCount={setPuppilaryDistanceCount}
        />
      ) : (
        <EyeglassesSteps
          tab={tab}
          step={step}
          file={file}
          setFile={setFile}
          setPrescription={setPrescription}
          upgrade={activeUpgrades}
          sections={sections}
          products={products}
          detailType={detailType}
          activeType={activeType}
          activeColor={activeColor}
          setDetailType={setDetailType}
          setActiveType={setActiveType}
          setActiveColor={setActiveColor}
          onUpgradeChange={onUpgradeChange}
          confirmationAccept={confirmationAccept}
          setConfirmationAccept={setConfirmationAccept}
          puppilaryDistanceCount={puppilaryDistanceCount}
          setPuppilaryDistanceCount={setPuppilaryDistanceCount}
        />
      )}
      <StepsFooter
        totalAmount={product.priceV2.amount}
        sections={sections}
        addToCart={onAddToCart}
        lastStep={step === count}
        goToNextStep={goToNextStep}
        setStep={setStep}
        tab={tab}
        products={products}
        activeColor={activeColor}
        activeType={activeType}
        activeUpgrades={activeUpgrades}
        file={file}
        step={step}
        detailType={detailType}
        puppilaryDistanceCount={puppilaryDistanceCount}
        prescription={prescription}
        confirmationAccept={confirmationAccept}
      />
      {infoModal && (
        <InfoModal
          closeModal={onClose}
          firstName={firstName}
          setFirstName={setFirstName}
          submitWithEmail={submitWithEmail}
          email={email}
          lastName={lastName}
          setEmail={setEmail}
          setLastName={setLastName}
          submitWithName={submitWithName}
        />
      )}
    </div>
  );
};

export default Steps;
